import React, { FC } from 'react';
import { TextField } from '@mui/material';
import { capitalize } from 'lodash';
import { ApplicationManagement } from '../../../reduxState/store/applicationManagement/types';

export interface AppReadOnlySettingsProps {
  applicationManagement: ApplicationManagement | null;
}

export const AppReadOnlySettings: FC<React.PropsWithChildren<AppReadOnlySettingsProps>> = ({
  applicationManagement,
}) => {
  const getSplitPolicyValue = (): string => {
    let policyValue = 'Policy Details not found';
    const splits = applicationManagement?.Policy?.Splits;

    if (splits) {
      policyValue = applicationManagement?.Policy.Name + ' - ';
      policyValue += splits.map(s => `${capitalize(s.Part)} ${s.Percentage}`).join(' / ');
    }

    return policyValue;
  };

  return (
    <>
      <TextField
        id="defaultCountry"
        className="settings-form-group"
        label="Default Country"
        value={applicationManagement?.Country || ''}
        disabled
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        id="defaultCurrency"
        className="settings-form-group"
        label="Default Currency"
        value={applicationManagement?.Currency || ''}
        disabled
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        id="serviceableCountries"
        className="settings-form-group"
        label="Serviceable Countries"
        value={applicationManagement?.ServiceableCountries?.join(', ') || ''}
        disabled
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        id="splitPolicy"
        className="settings-form-group"
        label={`Split Policy`}
        value={getSplitPolicyValue()}
        disabled
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
};
