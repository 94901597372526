import React, { useState } from 'react';
import { ContentCopy, Visibility, VisibilityOff } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { CircularProgress, IconButton, InputAdornment, Link, MenuItem, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import Button from 'components/Button/Button';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import TextField from 'components/TextField/TextField';
import { parseVersion } from 'helpers';
import { getErrorMessage } from 'helpers/getErrorMessage';
import { tryCatch } from 'helpers/tryCatch';
import { useAppSelector } from 'reduxState/hooks';
import { useGetApplicationDetailsQuery, useUpdateApplicationCallbackMutation } from 'reduxState/store/application/api';
import './ApplicationSetting.scss';
import { selectHasWildfireEmail } from 'reduxState/store/user/slice';

const versions = ['V2', 'V3', 'V4', 'V5'];

const ApplicationSetting = ({ applicationId }: { applicationId: string }): JSX.Element => {
  const [showCallbackKey, setShowCallbackKey] = useState(false);
  const [showExtensionSecret, setShowExtensionSecret] = useState(false);

  const hasWFEmail = useAppSelector(selectHasWildfireEmail);
  const isOwner = useAppSelector(state => state.user.roles).includes('owner');
  const canViewExtensionSecret = hasWFEmail || isOwner;

  const [updateApplicationCallback, { isLoading: isSaving }] = useUpdateApplicationCallbackMutation();

  const { data: application, isFetching, isSuccess, refetch: refetchApplication } = useGetApplicationDetailsQuery({
    appId: applicationId,
  });

  const handleSaveCallback = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const callbackURL = formData.get('callback-url') as string | null;
    const callbackKey = formData.get('callback-key') as string | null;
    const callbackVersion = (formData.get('callback-version') as string | null) ?? 'V5';

    if (!callbackURL) {
      toast.error('Missing callback url');
      return;
    }

    if (!callbackKey) {
      toast.error('Missing callback key');
      return;
    }

    const promise = updateApplicationCallback({
      appId: applicationId,
      callbackURL,
      callbackKey,
      callbackVersion,
    }).unwrap();

    const { error } = await tryCatch(promise);
    if (error) {
      toast.error(getErrorMessage(error));
      return;
    }

    toast.success('Updated callback');
  };

  if (isFetching) {
    return <Loading />;
  }

  if (!isSuccess) {
    return <Error retry={refetchApplication} />;
  }

  const {
    CallbackURL: callbackURL,
    CallbackKey: callbackKey,
    CallbackVersion: callbackVersion,
    ExtensionSecret: extensionSecret,
  } = application;

  return (
    <div className="application-setting">
      <h1 className="text-muted-dark-purple">Application Settings</h1>

      <form onSubmit={handleSaveCallback}>
        <TextField
          name="callback-url"
          variant="outlined"
          customLabel={
            <div className="application-setting-custom-label-container">
              <span>Callback URL</span>
              <Link
                href="https://kb.wildfire-corp.com/article/kfgi-syncing-commission-data-via-api-and-callbacks"
                target="_blank"
              >
                <InfoIcon />
              </Link>
            </div>
          }
          disabled={isSaving}
          defaultValue={callbackURL}
          inputProps={{ 'aria-label': 'Callback URL' }}
        />
        <TextField
          name="callback-key"
          label="Callback Key"
          variant="outlined"
          disabled={isSaving}
          defaultValue={callbackKey}
          type={showCallbackKey ? 'text' : 'password'}
          inputProps={{ 'aria-label': 'Callback Key' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowCallbackKey(!showCallbackKey)} size="large">
                  {showCallbackKey ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="callback-version"
          variant="outlined"
          customLabel={
            <div className="application-setting-custom-label-container">
              <span>Callback Version</span>
              <Tooltip title="Ensure the callback version you select matches the commission API version" arrow>
                <InfoIcon style={{ color: '#3f51b5' }} />
              </Tooltip>
            </div>
          }
          disabled={isSaving}
          defaultValue={parseVersion(callbackVersion)}
          inputProps={{ 'aria-label': 'Callback Version', 'data-testid': 'callback-version' }}
          select
          style={{ width: '20%' }}
          className="version-select"
        >
          {versions.map(version => (
            <MenuItem key={version} value={version}>
              {version}
            </MenuItem>
          ))}
        </TextField>
        <div className="disclaimer">
          The Callback URL and Callback Key are cached for 30 minutes. Please allow enough time for the cache to clear
          before testing changes.
        </div>
        <div className="application-setting-controls">
          <Button
            type="submit"
            className="button-orange application-setting-save-button"
            disabled={isSaving}
            endIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            Save
          </Button>
        </div>
      </form>

      {canViewExtensionSecret && (
        <div className="mt-10">
          <TextField
            label="Extension secret"
            variant="outlined"
            value={extensionSecret}
            type={showExtensionSecret ? 'text' : 'password'}
            inputProps={{ 'aria-label': 'Extension secret' }}
            InputProps={{
              disabled: true,
              endAdornment: (
                <InputAdornment position="end">
                  <div className="space-x-2 flex items-center">
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(extensionSecret).then(() => {
                          toast.success('Copied to clipboard');
                        });
                      }}
                    >
                      <ContentCopy />
                    </IconButton>
                    <IconButton onClick={() => setShowExtensionSecret(!showExtensionSecret)} size="large">
                      {showExtensionSecret ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ApplicationSetting;
