import React from 'react';
import { CircularProgress } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress';

export default function Loading(props: CircularProgressProps) {
  return (
    <div className="flex flex-1 justify-center items-center" data-testid="loading-icon">
      <CircularProgress {...props} />
    </div>
  );
}
